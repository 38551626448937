const cityRelayMetric = {
	"Victoria": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 50700.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 28192,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				6149.0,
				6510.0,
				6136.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 66217,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				6149.0,
				6510.0,
				6136.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0,
				4190.0
			]
		}
	],
	"Chelsea": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 42900.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 25172,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				5282.0,
				6698.0,
				4802.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 57347,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				3363.0,
				5282.0,
				6698.0,
				4802.0,
				3363.0,
				3363.0
			]
		}
	],
	"Mayfair": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 64324.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 30131,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				5612.0,
				6628.0,
				7847.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 78374,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5360.0,
				5612.0,
				6628.0,
				7847.0,
				5360.0
			]
		}
	],
	"Marylebone": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 51744.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 30516,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				5492.0,
				7508.0,
				7345.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 69324,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				4325.0,
				5492.0,
				7508.0,
				7345.0,
				4325.0
			]
		}
	],
	"Earl's Court": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 39000.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 21690,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				4644.0,
				5146.0,
				4670.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 50940,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0,
				4644.0,
				5146.0,
				4670.0,
				2778.0,
				2778.0,
				2778.0,
				2778.0
			]
		}
	],
	"Shepherd's Bush": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 25800.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 17655,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				4071.0,
				3883.0,
				3816.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 37005,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0,
				4071.0,
				3883.0,
				3816.0,
				1707.0,
				1707.0,
				1707.0,
				1707.0
			]
		}
	],
	"King's Cross": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 38400.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 20304,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				4689.0,
				4542.0,
				4305.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 49104,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				2262.0,
				4689.0,
				4542.0,
				4305.0,
				2262.0,
				2262.0,
				2262.0
			]
		}
	],
	"Westminster": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 51600.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 27021,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				5196.0,
				6626.0,
				6192.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 65721,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				5196.0,
				6626.0,
				6192.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0,
				4203.0
			]
		}
	],
	"Notting Hill": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 39000.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 22288,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				5214.0,
				5202.0,
				4443.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 51538,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0,
				5214.0,
				5202.0,
				4443.0,
				2431.0,
				2431.0,
				2431.0,
				2431.0
			]
		}
	],
	"Paddington": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 40800.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 21829,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				4168.0,
				5115.0,
				5270.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 52429,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				4168.0,
				5115.0,
				5270.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0,
				3389.0
			]
		}
	],
	"Hammersmith": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 28200.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 19916,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				3960.0,
				4559.0,
				4758.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 41066,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				3960.0,
				4559.0,
				4758.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0,
				2193.0
			]
		}
	],
	"Kensington": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 45600.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 25224,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				4144.0,
				6735.0,
				5937.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 59424,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0,
				4144.0,
				6735.0,
				5937.0,
				3306.0,
				3306.0,
				3306.0,
				3306.0
			]
		}
	],
	"Fulham": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 30000.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 19931,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				3839.0,
				5048.0,
				4400.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 42431,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				3839.0,
				5048.0,
				4400.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0,
				2188.0
			]
		}
	],
	"Covent Garden": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 48000.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 29209,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				6416.0,
				6572.0,
				6485.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 65209,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				5239.0,
				6416.0,
				6572.0,
				6485.0,
				5239.0,
				5239.0,
				5239.0
			]
		}
	],
	"Clapham": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 28200.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 15566,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				3647.0,
				3192.0,
				3539.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 36716,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				1980.0,
				3647.0,
				3192.0,
				3539.0
			]
		}
	],
	"Southwark": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 40800.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2366.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 22651,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				4891.0,
				5179.0,
				5030.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 53251,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				4891.0,
				5179.0,
				5030.0,
				2271.0,
				2271.0,
				2271.0,
				2271.0,
				2366.0
			]
		}
	],
	"Shoreditch": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 38400.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 20665,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				4674.0,
				4329.0,
				4774.0,
				0.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 49465,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				4674.0,
				4329.0,
				4774.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0,
				2366.0
			]
		}
	],
	"Canary Wharf": [
		{
			"id": "LONG_LET",
			"label": "Long let",
			"total": 39000.0,
			"borderColor": "hsl(219, 53%, 15%)",
			"data": [
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0
			]
		},
		{
			"id": "SHORT_LET",
			"label": "Short Let",
			"total": 24075,
			"borderColor": "hsl(47, 92%, 71%)",
			"data": [
				0.0,
				0.0,
				0.0,
				0.0,
				0.0,
				4870.0,
				5648.0,
				5532.0,
				0.0,
				0.0,
				0.0,
				0.0
			]
		},
		{
			"id": "FLEXIBLE_LET",
			"label": "Flexible Let",
			"total": 53325,
			"borderColor": "hsl(198, 77%, 53%)",
			"fill": true,
			"backgroundColor": "hsl(198, 77%, 73%)",
			"data": [
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0,
				4870.0,
				5648.0,
				5532.0,
				3020.0,
				3020.0,
				3020.0,
				3020.0
			]
		}
	]
}
const listNeighbourhoods = ['Victoria', 'Chelsea', 'Mayfair', 'Marylebone', "Earl's Court", "Shepherd's Bush", "King's Cross", 'Westminster', 'Notting Hill', 'Paddington', 'Hammersmith', 'Kensington', 'Fulham', 'Covent Garden', 'Clapham', 'Southwark', 'Shoreditch', 'Canary Wharf']

export { cityRelayMetric, listNeighbourhoods }
