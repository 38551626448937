import React ,{ useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

export default function Metric({ dataLine }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const [DataSet, setDataSet] = useState(
    {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", 'Sept', 'Oct', 'Nov', "Dec"],
      datasets: dataLine
    }
  )

  useEffect(() => {
    setDataSet(
      {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", 'Sept', 'Oct', 'Nov', "Dec"],
        datasets: dataLine
      }
    )

  }, [dataLine]
  )

  const legend = {
    display: false,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
    },
    scales: {
      y: {
        grid: {
          drawOnChartArea: false,
          color: "rgba(0, 0, 0, 0)",
          display: false,
        },
        ticks: {
          callback: function(value, index, values) {
            return '£' + value;
          },
        },
        min: 0,
        beginAtZero: true,
        suggestedMax: 8000,
      },
      x: {
        grid: {
          drawOnChartArea: false,
          color: "rgba(0, 0, 0, 0)",
          display: false,
        },
      },
    }
  };

  return (
    <div className='w-full md:self-center md:w-4/5'>
      <Line data={DataSet} legend={legend} options={options} />
    </div>
  )
}
