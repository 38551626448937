import React, { useEffect, useState } from 'react'
import TextCard from '~/components/molecules/TextCard'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import Metric from './Metric'
import {cityRelayMetric,listNeighbourhoods} from '~/data/metricData'


export default function PricingWidget({ key, index, postcodeParameter, ...props }) {

  const section = props.pricingWidget
  const [dropDownList, setDropDownList] = useState(false)
  const [cityValue, setCityValue] = useState('Victoria')
  const [flexibleLet, setFlexibleLet] = useState(false)
  const [shortLet, setShortLet] = useState(true)
  const [longLet, setLongLet] = useState(true)
  const [subData, setSubData] = useState(cityRelayMetric.Victoria);
  const [variable, setVariable] = useState('');
  const emptyData = [{"data": [{'x': 0, 'y': 0}]}]

  useEffect(() =>{
    // console.log('~~~PricingWidget.js');

    let auxData = []
    if(longLet){auxData.push(cityRelayMetric[cityValue][0])};
    if(shortLet){auxData.push(cityRelayMetric[cityValue][1])};
    if(flexibleLet){auxData.push(cityRelayMetric[cityValue][2])};
    setSubData(auxData)
  },[longLet,shortLet,flexibleLet,cityValue]
  )

  useEffect(() => {
    let datacity = [subData[0], subData[1]]
    setSubData(datacity)
    setFlexibleLet(false)
    setShortLet(true)
    setLongLet(true)
  }, [cityValue])

  return (
    <section id={section.fieldGroupName + index} className=" pt-24 bg-sand relative">
      <div className="container">
        <div className={`flex flex-col w-full h-full md:px-16`}>
          <FadeInWhenVisible className="flex flex-col xxl:items-center">
            <TextCard className="text-left " {...section.textCard} image={true} customAlign={true} headingClassName="text-center w-full md:w-35/5 lg:w-4/6 xl:w-25/4 xxl:w-3/5 mx-auto mb-8" contentDesign="xl:text-center" />
          </FadeInWhenVisible>

          {/* this is the neighbourhood component */}
          <FadeInWhenVisible className='flex flex-col md:flex-row md:justify-center text-center my-12 items-center z-10	'>
            <div className='text-h4 md:mr-4 mb-4 md:mb-0'>Neighbourhood*</div>
            <ButtonDropDown
              dropDownList={dropDownList}
              dropDownValue={cityValue}
              action={() => setDropDownList(!dropDownList)}
              setCityValue={setCityValue}
              setSubData={setSubData}
              listNeighbourhoods={listNeighbourhoods}
              
            />
          </FadeInWhenVisible>
        </div>
      </div>

      {/* this is the metric component, with buttons selection and final text */}
      <div className='flex flex-col bg-white container z-0 xxl:container'>
        <FadeInWhenVisible className="w-full flex flex-col mb-12 md:mt-0">
          <div className='lg:flex lg:flex-row lg:w-full'>
            <ButtonLetSelection cityValue={cityValue} className="hidden lg:flex flex-col w-3/12 items-center justify-end" flexibleLet={flexibleLet} shortLet={shortLet} longLet={longLet} setFlexibleLet={setFlexibleLet} setShortLet={setShortLet} setLongLet={setLongLet} setVariable={setVariable} subData={subData} />
            <div className='w-full flex flex-col'>
              <div className='text-h4 text-center my-12 lg:text-left lg:pl-20 xl:pl-24 xxl:pl-36'>Short, Long and Flexible Let</div>

              <div className='w-full h-20 flex flex-wrap justify-around mb-8 md:mb-0 md:px-40 lg:pr-32 lg:pl-4'>
                <MetricColors className="flex flex-row h-7 w-32 items-center" hrClassName="w-5 border-t-0 bg-yellow h-1" text="Short let" />
                <MetricColors className="flex flex-row h-7 w-32 items-center" hrClassName="w-5 border-t-0 bg-navy h-1" text="Long let" />
                <MetricColors className="flex flex-row h-7 w-32 md:w-36 items-center" hrClassName="w-5 border-t-0 bg-blue h-1" text="Flexible let" />
              </div>

              {(!flexibleLet && !shortLet && !longLet)
                ?
                  <Metric dataLine={emptyData} />
                :
                  <Metric dataLine={subData} />
              }

              <ButtonLetSelection cityValue={cityValue} className="flex lg:hidden flex-col md:flex-row w-full items-center px-4 mb-8 mt-16 md:my-12 md:px-20" flexibleLet={flexibleLet} shortLet={shortLet} longLet={longLet} setFlexibleLet={setFlexibleLet} setShortLet={setShortLet} setLongLet={setLongLet} setVariable={setVariable} subData={subData} />
            </div>
          </div>
          <div className='w-48 md:w-3/5 lg:w-2/4 h-20 md:h-14 bg-yellow p-4 flex items-center self-center justify-center mb-12 lg:mt-12'>
            <a href='/contact' className='cursor-pointer text-h5 text-center'>Get in touch</a>
          </div>
          
          {section.simpletext && <div className='xl:ml-28 font-heading text-h5 !font-normal !text-xl md:self-start mb-8' dangerouslySetInnerHTML={{ __html: section.simpletext.replace(/\[.*\]/g, '') }} />}
          {section.simpletextSecond && <div className='xl:ml-28 font-heading text-h5 !font-normal !text-xl md:self-start mb-4 xxl:w-4/5' dangerouslySetInnerHTML={{ __html: section.simpletextSecond.replace(/\[.*\]/g, '') }} />}
          {section.simpletextThird && <div className='xl:ml-28 font-heading text-h5 !font-normal !text-xl md:self-start' dangerouslySetInnerHTML={{ __html: section.simpletextThird.replace(/\[.*\]/g, '') }} />}

        </FadeInWhenVisible>
      </div>
    </section>
  )
}


function ButtonDropDown({ dropDownList, action, dropDownValue, setCityValue, setSubData, listNeighbourhoods }) {
  return (
    <button className='relative w-60 h-12 inline-block' onClick={action}>
      <div className='w-full flex flex-row justify-between text-h4 items-center'>
        {dropDownValue}
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L8 8L14 2" stroke="#12203A" stroke-width="3" />
        </svg>
      </div>
      <hr className='w-full border-t-0 bg-yellow h-0.5'></hr>
      {dropDownList &&
        <div className='absolute origin-top-right w-full bg-white right-0 mt-2 ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1 z-10'>
            {listNeighbourhoods.map((name) => 
               <div className='px-4 py-2 text-sm hover:bg-blue text-left' onClick={() => {
                  setSubData(cityRelayMetric[name])
                  setCityValue(name)
                }}>
                {name}
              </div>
            )}
          </div>
        </div>
      }
    </button>
  )
}

function ButtonLetSelection({ className, flexibleLet, shortLet, longLet, setFlexibleLet, setShortLet, setLongLet, setVariable, cityValue }) {

  return (
    <div className={className}>
      <div
        className={`cursor-pointer w-48 lg:w-44 h-24 p-4 flex flex-col justify-center mb-2 ${shortLet ? 'bg-yellow' : 'bg-sand'}`}
        onClick={() => {
          setVariable("SHORT_LET")
          setShortLet(!shortLet)
        }
        }
      >
        <p className='text-h5 text-center'>Short let*</p>
        <p className='text-h5 text-center'>£{new Intl.NumberFormat().format(cityRelayMetric[cityValue][1].total)}**</p>
      </div>
      <div
        className={`cursor-pointer w-48 lg:w-44 h-24 p-4 flex flex-col justify-center mb-2 ${longLet ? 'bg-navy text-white' : 'bg-sand'}`}
        onClick={() => {
          setVariable("LONG_LET")
          setLongLet(!longLet)
        }
        }
      >
        <p className='text-h5 text-center'>Long Let</p>
        <p className='text-h5 text-center'>£{new Intl.NumberFormat().format(cityRelayMetric[cityValue][0].total)}**</p>
      </div>
      <div
        className={`cursor-pointer w-48 lg:w-44 h-24 p-4 flex flex-col justify-center mb-2 ${flexibleLet ? 'bg-blue text-white' : 'bg-sand'}`}
        onClick={() => {
          setVariable('FLEXIBLE_LET')
          setFlexibleLet(!flexibleLet)
        }
        }
      >
        <p className='text-h5 text-center'>Flexible let</p>
        <p className='text-h5 text-center'>£{new Intl.NumberFormat().format(cityRelayMetric[cityValue][2].total)}**</p>
      </div>
    </div>
  )
}

function MetricColors({ className, hrClassName, text }) {
  return (
    <div className={className}>
      <hr className={hrClassName} />
      <div className='ml-4 text-body-large prose'>{text}</div>
    </div>
  )
}
